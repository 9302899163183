import Section from "../molecules/Section";
import Footer from "../organisms/Footer";
import Units from "../organisms/Units";
import Heading from "../atoms/Heading";
import Hero from "../molecules/Hero";
import BgCarImage from "../../assets/drive-teacher.png"

const UnitsPage = () => {
    return (
        <>
            <Hero image={BgCarImage}>
                <Heading>
                    <h1>
                        Nossas Unidades
                    </h1>

                </Heading>
            </Hero>

            <Section>
                <h3>Econtre a mais próxima de você</h3>

                <Units />
            </Section>
            <Footer />
        </>
    )
}

export default UnitsPage;
