import React from "react";
// import PropTypes from "prop-types";
import styled from "styled-components";
import {
    FaPhone,
    FaMailBulk,
    FaBuilding,
    FaInstagramSquare,
    FaFacebookF,
    FaLinkedin,
} from "react-icons/fa";

import Container from "../../components/atoms/Container";
import Grid from "../../components/atoms/Grid";
import Heading from "../../components/atoms/Heading";
import Map from "../molecules/Map";


const Root = styled.footer`
  background-color: #CF171A;
  color: #FFC107;               //#FEE93F
  padding: 40px 0;
  h6 {
    color: #fff;
    margin-top: 0;
    text-transform: uppercase;
  }
  p {
    margin: 8px 0;
  }
`;

const IconContainer = styled.span`
  padding-right: 8px;
`;

const FooterLink = styled.a`
  color: ${(props) => props.theme.colors.primary.main};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.primary.dark};
  }
`;

const Footer = () => {

    const currentYear = new Date().getFullYear();


    return (

        <Root>
            <Container>
                <Grid md={2}>
                    <div>
                        <Heading>
                            <h6>Auto Escola <strong>Mariana</strong></h6>

                            <Map />
                        </Heading>
                        <p>Seg a Sex: 08:00 as 20:00</p>
                    </div>
                    <div>
                        <Heading>
                            <h6>Contatos</h6>
                        </Heading>
                        <p>
                            <IconContainer>
                                <FaPhone />
                            </IconContainer>
                            (34) 3241-1943
                        </p>
                        <p>
                            <IconContainer>
                                <FaMailBulk />
                            </IconContainer>
                            cfcmariana2@yahoo.com.br
                        </p>
                        <p>
                            <IconContainer>
                                <FaBuilding />
                            </IconContainer>
                            <strong>unidade I -</strong> Rua Pedro Nasciutti, 175 Centro
                        </p>
                        <p>
                            <IconContainer>
                                <FaBuilding />
                            </IconContainer>
                            <strong>unidade II -</strong> Av. Cel. Teodolino Pereira Araújo, 711 Centro
                        </p>
                        <p>
                            <IconContainer>
                                <FaBuilding />
                            </IconContainer>
                            <strong>unidade III -</strong> Praça da constituição, 181 Centro
                        </p>
                        <p>
                            <IconContainer>
                                <FaBuilding />
                            </IconContainer>
                            <strong>unidade IV -</strong> Rua Padre Nicácio, 298 Amorim
                        </p>

                    </div>
                    <div>
                        <Heading>
                            <h6>Redes Sociais</h6>
                        </Heading>
                        <p>
                            <FooterLink target="_blank" href="https://www.instagram.com/autoescolamariana/">
                                <IconContainer>
                                    <FaInstagramSquare />
                                </IconContainer>
                                Instagram
                            </FooterLink>
                        </p>
                        <p>
                            <FooterLink target="_blank" href="https://www.facebook.com/AUTOESCOLAMARIANA1/?locale=pt_BR">
                                <IconContainer>
                                    <FaFacebookF />
                                </IconContainer>
                                Facebook
                            </FooterLink>
                        </p>
                    </div>
                    <div>
                        <Heading>
                            <h6>Desenvolvimento</h6>
                        </Heading>
                        <p>
                            <FooterLink target="_blank" href="https://www.linkedin.com/in/matheus-prado-de-oliveira/">
                                <IconContainer>
                                    <FaLinkedin />
                                </IconContainer>
                                Matheus Prado Dev
                            </FooterLink>
                        </p>
                    </div>
                    <footer>
                        &copy;
                        {currentYear} Centro de formação de condutores Mariana. Todos os direitos reservados.
                    </footer>
                </Grid>
            </Container>
        </Root>
    )
};
// Footer.defaultProps = {};

// Footer.propTypes = {};

export default Footer;
