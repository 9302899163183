import Unidade1 from "../assets/units/unidade1.jpg";
import Unidade2 from "../assets/units/unidade2.jpg";
import Unidade3 from "../assets/units/unidade3.jpg";
import Unidade4 from "../assets/units/unidade4.jpg";

const units = [
    { id: 1, name: "Unidade I", avatar: Unidade1, phoneNumber: "+5534991027666", description: "Rua Pedro Nasciutti, 175 Centro", map: "https://goo.gl/maps/mqLxxwPCEYx1nkHP8" },
    { id: 2, name: "Unidade II", avatar: Unidade2, phoneNumber: "+553432425125", description: "Av. Cel. Teodolino Pereira Araújo, 711 Centro", map: "https://goo.gl/maps/LivMNhfd3pAKyaEX7" },
    { id: 3, name: "Unidade III", avatar: Unidade3, phoneNumber: "+553435139698", description: "Praça da constituição, 181 Centro", map: "https://goo.gl/maps/RidDSoN7pJcLKE269" },
    { id: 4, name: "Unidade IV", avatar: Unidade4, phoneNumber: "+553435124007", description: "Rua Padre Nicácio, 298 Amorim", map: "https://goo.gl/maps/V84rMaAx5MMw7XbJ7" },
];

export const useUnits = () => {
    return units;
};

export const useUnit = ({ slang }) => {
    return units.find((p) => p.slang === slang);
};
