import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";

import Logo from "../molecules/Logo";

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #FFFFFF;  // alterei para branco a pedido
  position: sticky;
  top: 0;
  z-index: 999;
  height: 70px;
 
  /* @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  } */
`;

const NavigationList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    padding: 10px;
    z-index: 1000;
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  }
`;


const NavigationListItem = styled.li`
  margin-left: 20px;

  @media screen and (max-width: 768px) {
    margin: 10px 0;
  }
`;

const NavigationLink = styled(Link)`
  color: #FFC107;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const MenuButton = styled.button`
  display: none;
  background-color: transparent;
  border: none;
  color: #FFC107;
  font-size: 24px;
  cursor: pointer;
  position: relative;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;


const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <NavigationContainer>
            <Link to="/">
                <Logo />
            </Link>
            <nav>
                <MenuButton onClick={toggleMenu}>
                    <FaBars />
                </MenuButton>
                <NavigationList isOpen={isOpen}>
                    <NavigationListItem>
                        <NavigationLink to="/" onClick={toggleMenu}>Início</NavigationLink>
                    </NavigationListItem>
                    <NavigationListItem>
                        <NavigationLink to="/sobre" onClick={toggleMenu}>Sobre</NavigationLink>
                    </NavigationListItem>
                    <NavigationListItem>
                        <NavigationLink to="/unidades" onClick={toggleMenu}>Unidades</NavigationLink>
                    </NavigationListItem>
                </NavigationList>
            </nav>
        </NavigationContainer>
    );
}

export default Navigation;
