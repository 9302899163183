import React from "react";
import PropTypes from "prop-types";
import ProductType from "../../models/types/ProductType";

import { FaCar, FaKey, FaMapMarkedAlt, FaMotorcycle } from "react-icons/fa";
import { Link } from "react-router-dom";

import Hero from "../molecules/Hero";
import Heading from "../atoms/Heading";
import Button from "../atoms/Button";
import Grid from "../atoms/Grid";
import Feature from "../atoms/Feature";
import Section from "../../components/molecules/Section";
import Footer from "../../components/organisms/Footer";
import ProductGrid from "../../components/organisms/ProductGrid";
import Accordion, { AccordionGroup } from "../../components/atoms/Accordion";

import BgCarImage from "../../assets/drive-teacher.png";
import CarRoadVideo from "../../assets/car_road.mp4";

const Home = ({ products }) => (
    <>
        <Hero image={BgCarImage}>
            <Heading>
                <h1>
                    <strong>Habilitação</strong> em suas Mãos
                </h1>
            </Heading>
            <ul>
                <li>
                    Aprenda a dirigir com segurança e confiança em nossa auto escola.
                </li>
                <li>
                    Nossos instrutores experientes ajudarão você a dominar a arte de
                    dirigir.
                </li>
                <li>Obtenha sua licença de condução e liberdade na estrada conosco!</li>
            </ul>
            <Button as={Link} to="/sobre" color="primary" variant="outlined">
                Saiba Mais
            </Button>
        </Hero>
        <Section>
            <Grid sm={2} md={4}>
                <Feature icon={<FaCar />} title="Maior e melhor frota">
                    <p>Temos a maior e melhor frota para atender você com qualidade e segurança.</p>
                </Feature>
                <Feature icon={<FaKey />} title="Habilitação mais rápida">
                    <p>Obtenha sua habilitação de forma rápida e segura com nossos cursos intensivos.</p>
                </Feature>
                <Feature icon={<FaMapMarkedAlt />} title="Excelente localização">
                    <p>Nossas 4 unidades possuem uma excelente localização para atender você com facilidade e comodidade.</p>
                </Feature>
                <Feature icon={<FaMotorcycle />} title="Moto pista">
                    <p>Contamos com uma moto pista própria para treinar nossos alunos com segurança e eficiência.</p>
                </Feature>
            </Grid>
        </Section>
        <Section inverse>
            <Heading>
                <h2>Conheça nossos serviços</h2>
            </Heading>
            <ProductGrid products={products} />
        </Section>
        <Section>
            <Grid md={2}>
                <div>
                    <Heading>
                        <h2>Auto Escola <strong>Mariana</strong></h2>
                    </Heading>
                    <p>
                        o Centro de Formação de Condutores Mariana já formou mais de 100.000 (Cem mil) condutores, sempre mantendo a seriedade nos compromissos firmados com seus clientes e parceiros.
                    </p>
                    <div>
                        <Button as={Link} to="/sobre" color="primary">
                            Saiba mais
                        </Button>
                    </div>
                </div>
                <div>
                    <video
                        src={CarRoadVideo}
                        width="100%"
                        autoPlay
                        playsInline
                        loop
                        muted
                    />
                </div>
            </Grid>
        </Section>
        <Section inverse>
            <Heading>
                <h2>Dúvidas recorrentes</h2>
            </Heading>
            <AccordionGroup>
                <Accordion title="Como eu renovo minha CNH?">
                    Para renovar a sua CNH, você precisará agendar um exame médico e psicológico, além de realizar um curso de atualização e passar pelo processo de renovação junto ao Detran. O prazo para renovação varia de acordo com a idade do condutor e pode ser consultado no site do Detran da sua região. Mantenha a sua habilitação em dia e dirija com segurança!
                </Accordion>
                <Accordion title="Como faço a mudança de categoria?">
                    Para mudar a categoria da sua CNH, é necessário passar por um curso de formação específico para a categoria desejada, além de realizar exames teóricos e práticos. É importante lembrar que cada categoria tem requisitos específicos de idade, tempo de habilitação e de experiência na categoria atual. Entre em contato com a nossa auto escola e saiba mais sobre o processo de mudança de categoria e os cursos disponíveis.
                </Accordion>
                <Accordion title="Fui multado e agora?">
                    Se você foi multado, é importante verificar a infração cometida e o valor da multa. Você terá um prazo para recorrer da multa, caso deseje contestá-la. Caso contrário, basta realizar o pagamento da multa dentro do prazo estipulado para evitar possíveis penalidades, como a suspensão da CNH. Se você tiver dúvidas ou precisar de ajuda, nossa equipe está à disposição para orientá-lo no processo de recursos ou pagamento de multas.
                </Accordion>
                <Accordion title="Quanto tempo é necessário para tirar a primeira habilitação?">
                    O tempo necessário para tirar a primeira habilitação pode variar de acordo com a legislação e o processo de habilitação de cada país ou estado. No Brasil, por exemplo, o processo de habilitação inclui aulas teóricas, práticas e exames e pode levar em média 4 a 6 meses. No entanto, isso pode variar de acordo com a frequência das aulas e a disponibilidade do aluno para realizar os exames. Entre em contato com a nossa auto escola e saiba mais sobre o processo de habilitação e as opções de cursos disponíveis para você.
                </Accordion>
            </AccordionGroup>
        </Section>
        <Footer />
    </>
);
Home.defaultProps = {
    products: [],
};

Home.propTypes = {
    products: PropTypes.arrayOf(ProductType),
};

export default Home;
