import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ProductType from "../../models/types/ProductType"

import { Link } from "react-router-dom";
import Card, { CardMedia, CardBody } from "../../components/atoms/Card";
import Heading from "../../components/atoms/Heading";
import Button from "../../components/atoms/Button";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const ProductSlider = styled(Slider)`
  .slick-list {
    overflow: hidden;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    margin: 0px 10px;
    flex: 1 0 auto;
  }
`;

const PrevButton = styled.button`

    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;

`;

const NextButton = styled.button`

    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    

`



const ProductGrid = ({ products }) => {

    const settings =
    {
        dots: true,
        infinite: false,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <ProductSlider
                {...settings}
                prevArrow={<PrevButton>Prev</PrevButton>}
                nextArrow={<NextButton>Next</NextButton>}>
                {products.map((product) => (
                    <div key={product.id}>
                        <Card>
                            <CardMedia image={product.image} />
                            <CardBody>
                                <Heading>
                                    <h6>{product.title}</h6>
                                </Heading>
                                <p>materiais didáticos e aulas práticas para a obtenção da carteira de motorista.</p>
                                <div>
                                    <Button
                                        color="primary"
                                        variant="link"
                                        as={Link}
                                        to={`/servicos/${product.slang}`}
                                    >
                                        Saiba mais
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                ))}
            </ProductSlider>
            {/* {!showAll && (
                <ToolBar>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setShowAll(!showAll);
                        }}
                    >
                        Lista Completa de Serviços
                    </Button>
                </ToolBar>
            )} */}
        </>
    );
};
ProductGrid.defaultProps = {
    products: [],
};

ProductGrid.propTypes = {
    products: PropTypes.arrayOf(ProductType),
};

export default ProductGrid;
