import React from "react";
import styled from "styled-components";

import Hero from "../../components/molecules/Hero";
import Heading from "../../components/atoms/Heading";
import Section from "../../components/molecules/Section";
import Grid from "../../components/atoms/Grid";
import Footer from "../../components/organisms/Footer";
import BreadCrumb from "../../components/atoms/BreadCrumb";


import BgCarImage from "../../assets/drive-teacher.png";
import AboutImage from "../../draws/About";
import Units from "../organisms/Units";


const ImageContainer = styled.div`
  svg {
    width: 100%;
    height: auto;
    max-width: 380px;
    color: ${(props) => props.theme.colors.primary.main};
  }
`;


const About = () => (

    <>
        <Hero image={BgCarImage}>
            <Heading>
                <h1>Por que nos escolher</h1>
            </Heading>
            <BreadCrumb
                items={[{ label: "Início", link: "/" }, { label: "Sobre" }]}
            />
        </Hero>
        <Section>
            <Grid md={2}>
                <div>
                    <p>
                        Aqui, você encontrará uma equipe dedicada e preparada para ajudá-lo em cada etapa do processo de aprendizagem. Desde as aulas teóricas até as práticas, nossa missão é oferecer um serviço personalizado e adaptado às suas necessidades e objetivo
                    </p>
                    <p>
                        Acreditamos que a educação no trânsito é fundamental para a construção de um trânsito mais seguro e harmonioso. Por isso, estamos empenhados em formar condutores conscientes, responsáveis e comprometidos com a segurança no trânsito.
                    </p>
                    <ul>
                        <li>Frota de carros grande e nova</li>
                        <li>Experiência completa</li>
                        <li>Moto pista própria</li>
                        <li>Atendimento personalizado</li>
                        <li>4 unidades para lhe atender melhor</li>
                    </ul>
                </div>
                <div>
                    <p>
                        <p>
                            Venha fazer parte da Auto Escola Mariana e descubra como podemos ajudá-lo a conquistar a sua habilitação com qualidade, segurança e eficiência!
                        </p>
                    </p>
                    <center>
                        <ImageContainer>
                            <AboutImage />
                        </ImageContainer>
                    </center>
                </div>
            </Grid>
        </Section>
        <Section inverse>
            <Grid md={2}>
                <div>
                    <h4>Missão</h4>
                    <p>
                        Nós, da Auto Escola Mariana, estamos comprometidos em oferecer a melhor qualidade de ensino aos nossos alunos. Para isso, contamos com instrutores experientes e qualificados, que estão sempre atualizados em relação às mudanças na legislação de trânsito e às novas tecnologias disponíveis no mercado.
                    </p>
                </div>
                <div>
                    <h4>Visão</h4>
                    <p>
                        Nossa visão é ser a melhor escolha para quem busca aprender a dirigir de forma segura, confiável e eficiente. Acreditamos que, além de obter a habilitação, nossos alunos devem sair das aulas de direção com conhecimentos e habilidades que serão úteis para toda a vida.
                    </p>
                </div>
            </Grid>
        </Section>
        <Section>
            <Heading>
                <h2>Conheça nossas unidades</h2>
            </Heading>
            <Units />
        </Section>
        <Footer />
    </>
);

export default About;
