import React from "react";
import logo from "../../assets/logo-mariana-recorte-removebg-preview.png";
import styled from "styled-components";


const StyledLogo = styled.div`
    padding: 5px 0 0 15px;


`


const Logo = () => {
    return (
        <StyledLogo>
            <img src={logo} width={240} height={51} alt="Logo da minha empresa" />
        </StyledLogo>
    );
}

export default Logo;
