import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import ThemeProvider from "./styles/ThemeProvider";
import GlobalStyle from "./styles/GlobalStyle";
import Routes from "./routes";
import Navigation from "./components/organisms/Navigation";
import { WhatsAppWidget } from "react-whatsapp-widget";
import 'react-whatsapp-widget/dist/index.css';


function App() {
    return (
        <ThemeProvider>
            <GlobalStyle />
            <Router>
                <Navigation />
                <Routes />
                <WhatsAppWidget
                    phoneNumber='5534991027666'
                    replyTimeText='Normalmente responde dentro de um dia'
                    message={`Olá! 👋🏼\nO que podemos fazer por você?`}
                    companyName='Unidade I'
                    sendButtonText='Enviar'
                    inputPlaceHolder='Digite uma mensagem'
                />
            </Router>
        </ThemeProvider>
    );
}

export default App;
