import React from 'react';

const Map = () => {
    return (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.2155795302087!2d-48.19551541508315!3d-18.654319637253646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a431b08b238b7d%3A0x7e2b2cd6221f9169!2sR.%20Pedro%20Nasciuti%2C%20175%20-%20Centro%2C%20Araguari%20-%20MG%2C%2038440-134!5e0!3m2!1spt-BR!2sbr!4v1683142994065!5m2!1spt-BR!2sbr"
            width="300"
            height="180"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade">
        </iframe>
    );
};

export default Map;
