import React from "react";

import { useScrollToTop } from "../hooks/scroll";
import { useUnits } from "../hooks/units";
import UnitsPage from "../components/pages/UnitsPage";

const Units = () => {
    useScrollToTop();
    const units = useUnits();

    return <UnitsPage units={units} />;
};

export default Units;
