import Grid from "../atoms/Grid";
import Card from "../atoms/Card";
import { Link } from "react-router-dom";
import { CardMedia } from "../atoms/Card";
import { CardMediaDescription } from "../atoms/Card";
import Button from "../atoms/Button";
import Whatsapp from "../../assets/whatsapp.png"

import { useUnits } from "../../hooks/units";


import styled from "styled-components";



const ImageWhatsApp = styled.div`
    img {
        padding-right: 8px;
        margin-bottom: -5px;
    }
`


function redirectToWhatsApp(phoneNumber) {

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
        // WhatsApp Mobile
        window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`);
    } else {
        // WhatsApp Web
        window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}`);
    }
    console.log(navigator.userAgent)
}

const Units = () => {

    const units = useUnits();

    return (

        <Grid sm={2} md={3} lg={4}>
            {units.map((units) => (
                <Card key={units.id}>
                    <Link
                        className="link-style"
                        to={units.map}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <CardMedia image={units.avatar}>
                            <CardMediaDescription>
                                <h5><strong>{units.name}</strong></h5>
                                <h6>{units.description}</h6>
                                <Button
                                    color="primary"
                                    variant="link"
                                    to={`/servicos/${units.slang}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        redirectToWhatsApp(units.phoneNumber)
                                    }}
                                >
                                    <ImageWhatsApp>
                                        <img src={Whatsapp} alt="WhatsApp" />
                                        WhatsApp
                                    </ImageWhatsApp>
                                </Button>
                            </CardMediaDescription>
                        </CardMedia>
                    </Link>
                </Card>

            ))}
        </Grid>
    )
}

export default Units;
