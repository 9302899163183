import Moto_carImage from "../assets/aprovado-carro.jpg";
import CarImage from "../assets/aprovado-car.jpg";
import MotoImage from "../assets/aprovado-moto.jpg";
import TruckImage from "../assets/aprovado-carreta.jpg";
import CnhImage from "../assets/habilitados.jpg";
import AvulsoImage from "../assets/aula-avulsa.jpg";

const products = [
    {
        id: 1,
        image: Moto_carImage,
        title: "1ª Habilitação Carro e Moto",
        slang: "primeira-habilitacao-carro-e-moto",
        sumary: "Para a primeira carteira de carro ou moto...",
    },
    {
        id: 2,
        image: CarImage,
        title: "1ª Habilitação Carro",
        slang: "primeira-habilitacao-carro",
        sumary: "Para a primeira carteira de carro...",
    },
    {
        id: 3,
        image: MotoImage,
        title: "1ª Habilitação Moto",
        slang: "primeira-habilitacao-moto",
        sumary: "Para a primeira carteira de moto...",
    },
    {
        id: 4,
        image: TruckImage,
        title: "Mudança de Categoria",
        slang: "mudanca-de-cateoria-caminhao",
        sumary: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    },
    {
        id: 5,
        image: AvulsoImage,
        title: "Aulas Avulsas",
        slang: "aulas-avulsas",
        sumary: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    },
    {
        id: 6,
        image: CnhImage,
        title: "Treinamento para habilitados",
        slang: "treinamento-para-habilitados",
        sumary: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    },
];

export const useProducts = () => {
    return products;
};

export const useProduct = ({ slang }) => {
    return products.find((p) => p.slang === slang);
};
